<script>
import { mapState, mapActions } from "vuex";
import { SUBSCRIPTION_QUESTIONS } from "@/package/const/subscription-questions";
import { compareAsc } from "date-fns";
import SubscriptionItem from "@/components/subscription/SubscriptionItem.vue";
import SubscriptionHistoryList from "@/components/subscription/SubscriptionHistoryList";
import MainCollapse from "@/components/helpers/MainCollapse";
import MainSelect from "@/components/helpers/MainSelect";

export default {
  name: "Subscription",

  components: {
    MainCollapse,
    SubscriptionHistoryList,
    SubscriptionItem,
    MainSelect,
  },

  data() {
    return {
      selectedSubscriptionType: {
        id: 1,
        name: "Раз в месяц",
        price: 400,
        mode: "monthly_payment",
      },

      subscriptionTypes: [
        {
          id: 1,
          name: "Раз в месяц",
          price: 400,
          mode: "monthly_payment",
        },
        {
          id: 2,
          name: "Раз в год",
          price: 3000,
          mode: "annual_payment",
        },
      ],

      params: {
        page: 1,
      },

      sortedSubscriptionsDates: [],

      QUESTIONS: SUBSCRIPTION_QUESTIONS,
    };
  },

  watch: {
    params: {
      deep: true,
      handler() {
        this.loadSubscriptionHistory(this.params);
      },
    },

    selectedSubscriptionType: {
      deep: true,
      handler() {
        if (
          this.subscriptionPaymentMode !==
          "d_" + this.selectedSubscriptionType.mode
        ) {
          this.changeSubscriptionPaymentMode({
            mode: this.selectedSubscriptionType.mode,
          });
        }
      },
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      subscriptions: (state) => state.subscription.subscriptions,
      subscriptionPaymentMode: (state) =>
        state.subscription.subscriptionPaymentMode,
      subscriptionHistory: (state) => state.subscription.subscriptionHistory,
      subscriptionHistoryLastPage: (state) =>
        state.subscription.subscriptionHistoryLastPage,
    }),
  },

  methods: {
    ...mapActions({
      loadSubscriptions: "subscription/loadSubscriptions",
      loadSubscriptionHistory: "subscription/loadSubscriptionHistory",
      changeSubscriptionPaymentMode:
        "subscription/changeSubscriptionPaymentMode",
      checkUser: "auth/checkUser",
    }),

    sortSubscriptionsDates(subscriptions) {
      let dates = [];

      if (subscriptions.length > 0) {
        subscriptions.forEach((subscription) => {
          dates.push(new Date(subscription.active_until));
        });

        this.sortedSubscriptionsDates = dates.sort(compareAsc);
      }
    },
  },

  mounted() {
    this.loadSubscriptions().then(() => {
      this.sortSubscriptionsDates(this.subscriptions);

      if (!this.subscriptionPaymentMode) {
        this.changeSubscriptionPaymentMode({ mode: "monthly_payment" });
      }

      if (
        "d_" + this.selectedSubscriptionType.mode !==
        this.subscriptionPaymentMode
      ) {
        this.selectedSubscriptionType = this.subscriptionTypes.find((type) => {
          return "d_" + type.mode === this.subscriptionPaymentMode;
        });
      }
    });

    this.loadSubscriptionHistory(this.params);
  },
};
</script>

<template>
  <div class="subscription">
    <!--    <subscription-error-->
    <!--      v-if="showExpiredSubscriptionNotice"-->
    <!--      :description="`Вы не оплачивали абонентскую плату на протяжении 10 месяцев. Если не возобновить платежи в течение месяца, начиная с ${formattedExpiredDate} , то Ваш тариф изменится на Клиент Стандарт, а pv, cv и квалификация сгорят.`"-->
    <!--    />-->

    <div class="subscription__settings">
      <main-select
        :selected-option.sync="selectedSubscriptionType"
        :options="subscriptionTypes"
        style-type="background-white"
      />
    </div>

    <div class="subscription__items">
      <subscription-item
        v-for="subscription in subscriptions"
        :key="subscription.id"
        :subscription="subscription"
        :subscription-type="selectedSubscriptionType"
        @updateSubscriptions="loadSubscriptions"
        @updateBalance="checkUser"
      />
    </div>

    <div class="subscription__history">
      <subscription-history-list
        :subscription-history="subscriptionHistory"
        :subscription-history-current-page="params.page"
        :subscription-history-last-page="subscriptionHistoryLastPage"
        @nextPage="params.page++"
      />
    </div>

    <div class="subscription__questions">
      <h2>Частые вопросы</h2>

      <main-collapse
        v-for="question in QUESTIONS"
        :key="question.id"
        class="question"
        :title="question.title"
      >
        <div class="question__text" v-html="question.text"></div>
      </main-collapse>
    </div>
  </div>
</template>

<style scoped lang="scss">
.subscription {
  padding: 0 $space-xxl;

  @media (min-width: 426px) and (max-width: 576px) {
    padding: 0 $space-xl;
  }

  @media (max-width: 425px) {
    padding: 0 $space-l;
  }

  @media (max-width: 375px) {
    padding: 0 $space-m;
  }

  &__selector {
    display: inline-flex;
  }

  &__description {
    @include body-1;
    color: $dark-fifth;
    margin: 0 0 $space-xl;
  }

  &__settings {
    display: flex;
    gap: $space-m;
    margin-bottom: $space-xl;

    > div {
      max-width: 210px;
      width: 100%;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    grid-column-gap: $space-l;
    grid-row-gap: $space-xl;
    margin-bottom: $space-xxl;

    @media (max-width: 576px) {
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }

  &__history {
    margin-bottom: $space-xxl;
  }

  &__questions {
    h2 {
      @include title-3;
      color: $dark-primary;
      margin-bottom: $space-l;
    }
  }

  .question {
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 48px;
    }

    &__text {
      padding-top: 24px;
      @include text-2;
      color: $dark-primary;
    }
  }
}
</style>
